import MatchCard from "./matchcard";
import React from "react";
import "./matchtable.css";

export default  function MatchTable(props: { index: number | string; matches: any[]; childRef: any; hidden?: boolean; heights?: any[]}) {
    const heights: any[] = [];
    return (
    <table className="matchTable" id={`MatchTable${props.index}`} ref={props.childRef} style={{visibility: props.hidden ? "hidden" : "visible"} } >
        <tbody>
        {props.matches

            .map(m=> <MatchCard key={m.id}
                                id={m.id}
                                station={m.station.stationMaster.prefix + ' ' +  m.station.increment}
                                rosters={m.match.connectedSeeds}
                                heights={props.heights && !props.heights.length ? props.heights : null} />
            )
        }
        </tbody>
    </table>
    )
}
