import {
    ReactElement,
    JSXElementConstructor,
    ReactNode,
    ReactPortal,
    Fragment,
    useRef,
    useLayoutEffect,
    Ref, useEffect
} from 'react';
import './matchcard.css'

export default function MatchCard(
    params: {
        id: number;
        station: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
        rosters: { id: string,  seedNumber: number, roster: { id: number, name: any; }; }[];
        heights?: any[] | null | undefined;
    }) {

    const cardRef: Ref<HTMLTableRowElement> = useRef(null);
    useEffect(() =>{
        if (!params.heights || !cardRef.current) return;
        const { height } = cardRef.current.getBoundingClientRect();
        params.heights.push({id: params.id, height: height})
    })

    return (
        <tr className="matchcard" ref={cardRef}>
            <td className="station">
                <span className="stationname">{params.station}</span>
            </td>
            <td className="rosters">
            {
                params.rosters
                    .map((r, index) => <Fragment key={r.id}>
                        {!!index && <span className="vs">{ params.rosters.length < 3 ? " vs " : " "}</span>}
                        <span>
                            <span className="seedNumber">#{r.seedNumber}</span
                            >&nbsp;{r.roster.name
                            //.substring(0, r.roster.name.lastIndexOf("#"))
                            .replace(/ /g, "\u00A0")}</span>
                            </Fragment>)
            }
            </td>
        </tr>
    )
}

