import React, {useState, useEffect, useRef, ReactElement, useMemo} from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import MatchTable from './matchtable';
import './App.css';
import Cookies from 'universal-cookie';

function reloadWindow() {
  window.location.reload();
}

function isFullScreen() {
  return window.innerHeight === window.screen.height;
}


function getWindowDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

function makeMatchList(heats: any[]) {
  const smatches = [];
  for (const heat of heats) {
    smatches.push(...heat.scheduledMatches)
  }
  //TODO: This must also accommodate ALPHA station IDs for TFT!
  return smatches.sort((a, b)=> a.station.increment - b.station.increment);
}

function App() {
  const cookies = new Cookies(null, { path: '/'});
  const token = cookies.get('machine_token')
  const [screenSize, setScreenSize] = useState(getWindowDimensions);

  const [heats, setHeats] = useState<any[]>([]);
  const [matches, setMatches] = useState<any[]>([]);
  const [dimensions, setDimensions] = useState({tableStart: 0, height: 0});
  const [currentPage, setCurrentPage] = useState(0);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [pageTables, setPageTables] = useState<any[]>([]);
  //const [heights, setHeights] = useState<any[]>([])
  const timerRef: any = useRef();
  const headerRef: any = useRef();
  const mainTableRef = useRef();
  const tableRefs = useRef(new Array());
  const cardRefs = useRef([]);


  const heights: any[] = [];

  // background calculation of pages for next iteration
  function calculatePages() {
    const PADDING = 1.2; // this must be adjusted if tr margins are changed

    if (heights.length === 0) return;
    const tableSet = [];
    const refSet: any[] = [];
    const header = headerRef.current;
    const t1: any = mainTableRef.current;
    if (!header || !t1) return;

    const {bottom} = header?.getBoundingClientRect() ?? {bottom: 0};
    const {height} = getWindowDimensions();
    setDimensions({tableStart: bottom, height})

        if (t1) t1.style.top = `${height}px`;
    const {height: tableHeight} = t1.getBoundingClientRect()
    const contentRoom = height - bottom;

    let currentPage = 0;
    let spaceTaken = 0;
    let pageMatches = [];

    for (const match of matches) {

      const miq = heights.find(m => m.id === match.id);
      if (!miq) {
        console.error("Match not found!", match.id);
        continue;
      }
      if (spaceTaken + (miq.height * PADDING) > contentRoom) {
        const pageElem = <MatchTable index={currentPage} matches={pageMatches}
                                     childRef={(element: ReactElement) => refSet.push(element)}/>
        tableSet.push(pageElem);
        currentPage += 1;
        spaceTaken = 0;
        pageMatches = [];
      }
      pageMatches.push(match)
      spaceTaken += (miq.height * PADDING);
    }
    if (pageMatches.length) {
      const pageElem = <MatchTable index={currentPage} matches={pageMatches}
                                   childRef={(element: ReactElement) => refSet.push(element)}/>
      tableSet.push(pageElem);
    }
    tableRefs.current = refSet;
    //return tableSet;
    setPageTables(tableSet);
  }

  const fetchSchedule = async () => {
    if (!cookies.get('machine_token') || codeData?.code) return;
    const params = new URLSearchParams(window.location.search);
    const screen = params?.get("sid");
    const path = screen ? `?sid=${screen}` : ""
    try {
      const url = `${process.env.REACT_APP_API}/api/admin/info/${path}`;
      console.log("API", url);
      const response = await axios.get(url, {headers: {Authorization: token}});
      if (response.status !== 200) return null;
      return response.data;
    } catch (e) {
      console.error(e);
      return "";
    }
  }

  const fetchCode = async () => {
    const params = new URLSearchParams(window.location.search);
    try {
      const url = `${process.env.REACT_APP_API}/api/admin/info/code`;
      console.log("API", url);
      const response = await axios.get(url, {headers: {Authorization: token}});
      if (response.status !== 200) return null;
      if (!response.data) return;
      cookies.set('machine_token', response.data.publicId);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  function nextPage() {
    setCurrentPage(p=> {
      let newPage = p + 1;
      if (newPage >= tableRefs.current.length) {
        newPage = 0;
      }
      return newPage;
    });
  }

  //const pageTables = useMemo(calculatePages, [matches, screenSize]);
  useEffect(()=>{
    calculatePages();
  }, [matches, screenSize]);

  useEffect(()=>{
    const ta: any = mainTableRef.current;
    //if (ta) ta.style.top = `${dimensions.height}px`;
    for (let i = 0; i < tableRefs.current.length; i++) {
      const elem = tableRefs.current[i];
      if (!elem) continue;

      if (i === currentPage) {
        elem.classList.remove("out");
        elem.style.zIndex = 100;
        elem.classList.add("in");
        elem.style.top = `${dimensions.tableStart}px`;
      } else {
        elem.classList.remove("in");
        elem.classList.add("out");
        elem.style.zIndex = 0;
      }
    }
  }, [currentPage, dimensions.height, dimensions.tableStart])

  useEffect(()=>{
    timerRef.current = setInterval(nextPage, 10000);
    return () => clearInterval(timerRef.current);
  }, [])  // only on 1st render for now

  const {
    data: schedule,
    error,
    isLoading
  } = useQuery(["schedule"], fetchSchedule, {refetchInterval: 30000})


  const { data: codeData, refetch: codeFetch } = useQuery(["new_machine"], fetchCode, {
    refetchOnWindowFocus: false,
    enabled: false // disable this query from automatically running
  });

  useEffect( () => {
    codeFetch(token);
  }, [token])

  useEffect(()=>{
    const heats: any[] = [];
    if (!schedule) return;
    schedule.schedule?.timeFrames?.forEach((tf: { heats: any; }) => {
      heats.push(...tf.heats)
    })
    setHeats(heats)
  }, [schedule])

  useEffect(() => {
    const updateDim = () => {
      const newSize = getWindowDimensions();
      //calculatePages();
      setScreenSize(newSize);
    }

    window.addEventListener('resize', updateDim);
    return(() => {
      window.removeEventListener('resize', updateDim);
    })
  }, [screenSize])


  useEffect(() => {
    if (!heats.length) return;
     setMatches(makeMatchList(heats))
  }, [heats]);

  function setFullScreen() {
    const elem = document.documentElement;
    elem.requestFullscreen();
    setFullScreenMode(true);
  }

  function selectScreen(screen: number) {
    // setFullScreen();
    const suffix = window.location.href.includes("?") ? "&" : "?";
    window.location.href = `${window.location.href}${suffix}sid=${screen}`
  }

  const auth = cookies.get('machine_token');
  if (!auth || codeData?.code) {
      return <div className="activation_code">
        <h3>Machine Activation Code</h3>
        <h1>{codeData?.code}</h1>
        <button onClick={reloadWindow}>Authorize</button>
      </div>
  } else {
    if (schedule?.tournaments) {
      if (schedule.tournaments.length){
        return <div className="activation_code">
          <div>Select a Tournament</div>
          <div>
            {schedule.tournaments.map((t: any) => <button key={t.publicId}>{t.name}</button>)}
          </div>
        </div>
      } else {
        return <div className="activation_code">
          <div>{schedule.machine}</div>
          <div>No tournaments assigned</div>
        </div>
      }
    }
    if (schedule?.screens) {
      if (schedule.screens.length) {
        return <div className="activation_code">
          <div>Select a Screen</div>
          <div>
            {schedule.screens.map((s: any) => <button key={s.publicId} onClick={()=>selectScreen(s.screen)}> Screen {s.screen}</button>)}
          </div>
        </div>
      } else {
        return <div className="activation_code">
          <div>{schedule.machine}</div>
          <div>No screen or phase assigned</div>
        </div>
      }
    }
  }

  if (!schedule?.status) {
    const params = new URLSearchParams(window.location.search);
    const screen = params?.get("sid") ?? 1;
    return <div className={"activation_code"}><div>Screen {screen}</div><div>Select a match status</div></div>
  }

  if (isLoading) return <div>Loading...</div>

  if (error) return <div>Something went wrong.</div>
  if (!heats) return null;
  return (
    <div className="App">
  <div className="Header"  ref={headerRef} id="header"><div>{schedule.name}<img src="/TFTVO-bug.png" width="80rem" alt=""/></div>
    <div className="statusLine">{schedule.status?.toUpperCase() + " MATCHES"}</div>
  </div>
      {fullScreenMode && <>
      <MatchTable matches={matches} index="" childRef={mainTableRef} hidden={true} heights={heights} />
      {(pageTables ?? []).map(p=>p)}
      </>}
      { !fullScreenMode && <button id="FOO_BTN" onClick={setFullScreen}>FULLSCREEN</button>}

    </div>
  );
}

export default App;
